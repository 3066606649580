require("@rails/ujs").start();
require("turbolinks").start();

import Vue from "vue";
const HubApp = () => import("hub/App.vue");
const App = () => import("dependencies/application.js");
const AdminApp = () => import("dependencies/admin.js");
const CustomerSupportApp = () => import("dependencies/customer_support.js");
const HomeApp = () => import("dependencies/home.js");
const NewHub = () => import("dependencies/new_hub.js");

import DisplaySettingsApp from "DisplaySettings.vue";

import TurbolinksAdapter from "vue-turbolinks";
Vue.use(TurbolinksAdapter);

import "stylesheets/application.js";

import axios from "axios";

document.addEventListener("turbolinks:load", () => {
  const displaySettingsEl = document.getElementById("display-settings-app");
  if (displaySettingsEl) {
    new Vue({
      el: displaySettingsEl,
      render: (h) => h(DisplaySettingsApp),
    });
  }

  const metaValue = document.querySelector("meta[name=csrf-token]");
  if (metaValue) {
    axios.defaults.headers.common["X-CSRF-Token"] = metaValue["content"];
  }
  const hubEl = document.getElementById("hub-app");
  if (hubEl) {
    const app = new Vue({
      el: hubEl,
      render: (h) => h(HubApp),
    });
  }
  const adminEl = document.getElementById("admin-app");
  if (adminEl) {
    AdminApp();
  }
  const customerSupportEl = document.getElementById("customer-support");
  if (customerSupportEl) {
    CustomerSupportApp();
  }
  const homeEl = document.getElementById("home-app");
  if (homeEl) {
    HomeApp();
  }
  const appEl = document.getElementById("app");
  if (appEl) {
    App();
  }

  const newHubEl = document.getElementById("new-hub");
  if (newHubEl) {
    NewHub();
  }

  if (document.querySelector("#flash-notice")) {
    setTimeout(() => {
      document.querySelector("#flash-notice").remove();
    }, 5000);
  }
});
